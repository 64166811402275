import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Row } from "antd";
import Link from "next/link";
import Image from "next/image";
// import Image from "next/image";
// import { v4 as uuidv4 } from "uuid";
// import img1 from "../../../../public/assets/images/slide-1.jpg";
// import img2 from "../../../../public/assets/images/meeting.jpg";
// import img3 from "../../../../public/assets/images/collaborative_workspace.jpg";
// import img4 from "../../../../public/assets/images/dedicated_desk.jpg";
// import img5 from "../../../../public/assets/images/coming-soon.jpg";
// import privateOffice from "../../../../public/assets/images/img__1.jpg";
// import meetingRoom from "../../../../public/assets/images/img__2.jpg";
// import dedicatedDesk from "../../../../public/assets/images/img__3.jpg";
// import flexiDesk from "../../../../public/assets/images/img__4.jpg";
import comingsoon from "../../../../public/assets/images/img__5.jpg";
import availability from "../../../../public/assets/images/availability.jpg";
import arrowIcon from "../../../../public/assets/images/arrow-icon.svg";
import tallyWinner from "../../../../public/assets/images/the-tallys-winner.png";

// import Loading from "../../../components/utils/Loading";
import Slider from "react-slick";
import HomeStyle from "../../../styles/sass/home.module.scss";
/* import Locationpopup from "../../utils/Locationpopup";
import LearnMore from "../../utils/LearnMore"; */

import { useDispatch, useSelector } from "react-redux";

import { resourceTypes } from "../../../utils/CommonFunctions";
import constants from "../../../utils/Constants";

const { CMS_TYPES, CMS_PAGES } = constants;

const Sliderbanner = ({ openPopup, popupValue, contentPopup, contentPopupEvent, showLandingPage, handleLearnMorePopup, handleLocationPopup}) => {

  const sliderRef = useRef(null);

  // const [showLearnMore, setShowLearnMore] = useState(false);
  // const [showLocationPopup, setShowLocationPopup] = useState(false);
  // const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  /* const handleLocationPopup = useCallback((show) => {
    show = typeof show === "boolean" && show;
    setShowLocationPopup(show);
  }, []);

  const handleLearnMorePopup = useCallback((show) => {
    show = typeof show === "boolean" && show;
    setShowLearnMore(show);
  }, []); */

  const { cmsMap, boards } = useSelector((state) => state?.cms);
  const cmsBoard = useMemo(
    () => boards?.[JSON.stringify({ type: CMS_TYPES.SLIDER })],
    [boards]
  );

 /*  const sliderPageId = useMemo(() => {
    const sliderBoardKey = JSON.stringify({
      type: CMS_TYPES.PAGE_META,
      page_name: CMS_PAGES.HOME,
    });
    return boards[sliderBoardKey]?.list?.[0];
  }, [boards]); */


  // useEffect(() => {
  //   const params = { type: "Slider" };
  //   setBoardKey(params);
  // }, [dispatch, cmsBoard]);

  useEffect(() => {
    if (showLandingPage) {
      sliderRef.current.slickGoTo(0);
    }
  }, [showLandingPage]);

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      // speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 2000,
      pauseOnHover: false,
      adaptiveHeight: true,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            /*  dots: true */
          },
        },
      ],
    }),
    []
  );

return (
    <>

      {/* <Locationpopup
        mapImage={"/assets/images/map.jpg"}
        popupValue={showLocationPopup}
        closePopup={handleLocationPopup}
      />

      <LearnMore
        pageId={sliderPageId}
        show={showLearnMore}
        handleDrawer={handleLearnMorePopup}
      /> */}

      <div className={HomeStyle.slider_o_cb}>
        <div className={`the-tallys-winner`}>
          <Image
          src={tallyWinner?.src}
          alt="the tallys winner 2023"
          width={200}
          height={142}
          />
        </div>

        <div area-hidden="true"  className={HomeStyle.slider_center_mob}>
          <div className={HomeStyle.slider_center_mobile}>
            <Slider
              {...settings}
              className="full_width_cb"
              ref={sliderRef}
              // beforeChange={(_, nextSlide) => setCurrentSlideIndex(nextSlide)}
              // afterChange={(currentSlide) => setCurrentSlideIndex(currentSlide)}
            >
              {cmsBoard?.list?.map((id, i) => {
                const mCmsSlider = cmsMap?.[id];
                const resource_type = mCmsSlider?.meta?.resource_type;
                const resourceType = resourceTypes?.[resource_type];

                const heading = mCmsSlider?.name || resourceType?.name;
                const description = mCmsSlider?.description;
                const mPicture = mCmsSlider?.pictures?.[0];
                const imgUrl = mPicture?.url;
                const altTag = mPicture?.tag || "slide images";
                const ids = mCmsSlider?._id
                // const resourcePrice =
                //   mCmsSlider?.price && Number(mCmsSlider?.price);
                const resourcePrice = mCmsSlider?.price;

                let price;
                if (resourcePrice) {
                  // if (resourceType?._id === "meeting-rooms") {
                  //   price = `${mCmsSlider?.price} /hr`;
                  // } else {
                  //   price = `${mCmsSlider?.price} pcm`;
                  // }

                  price = mCmsSlider?.price;
                } else if (resourcePrice === 0) {
                  price = `tbc`;
                }

                console.log(ids, "mCmsSlider", typeof ids)


                return (
                  <div className={HomeStyle.slide_wrapper} key={id}>
                    <div
                      className={`${HomeStyle["slide-element"]} mc_container_inner`}
                    >
                      <div className={HomeStyle.wrap_mobile}>
                        <div
                          className={`${HomeStyle["slide-img"]}`}
                        >
                          <Image
                            src={imgUrl}
                            alt={altTag}
                            objectFit="cover"
                            layout="fill"
                            priority={false}
                          />

                          {/* <div
                            className={HomeStyle.m_view_d_cb}
                            style={{
                              background: `#ccc url(${imgUrl})`,
                            }}
                          ></div>
                          <div className="loader_cb">
                            <div className="loading_text_data">
                              Loading Image...
                            </div>
                          </div> */}
                        </div>
                        <div className={HomeStyle["slide-content"]}>
                          {/* ${
                              resourceType?._id === "meeting-rooms"
                                ? HomeStyle["add_mb_spacer"]
                                : ""
                            } */}
                          <h4
                            className={`${HomeStyle["page-heading"]}`}
                          >
                            {heading}

                            <span>5 minutes from London Bridge</span>
                          </h4>

                          {/* {resource_type === "other" && (
                            <h3 className={HomeStyle.main_content}>
                              Garden Office Bermondsey
                            </h3>
                          )} */}
                          <div className={HomeStyle["info-wrap"]}>
                            <p className={HomeStyle["slide-lead"]}>
                              {description}
                            </p>

                            {resourceType && (
                              <p className={HomeStyle["slide-lead"]}>
                                from <strong>{price}</strong>
                              </p>
                            )}

                            <div className="btn_cb_ob">
                              {/* <span
                              className="km_btn"
                              onClick={() => contentPopupEvent(true, id)}
                            >
                              Know More
                            </span> */}
                              <Link
                                  href={
                                    resource_type === "other"
                                      ? ids === "67ce90ac3bd50fa3151095f8"
                                        ? "https://go-bermondsey.co.uk/blog/best-transport-links-to-office-spaces-in-bermondsey/"
                                        : "/availability"
                                      : `/office-spaces/${resourceType?._id || resource_type}`
                                  }
                                >
                                <a className={HomeStyle["arrow-link"]}>
                                  <>
                                    View Details
                                    <i>
                                      <Image
                                        src={arrowIcon.src}
                                        alt="arrow-icon"
                                        width={23}
                                        height={10}
                                      />
                                    </i>
                                  </>
                                </a>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className={HomeStyle.slide_wrapper}>
                <div
                  className={`${HomeStyle["slide-element"]} mc_container_inner`}
              >
                <div className="home-text-section">
                  <div className="c-text-container text-center">
                    <div className="h-title white-color">
                      <h1 className="heading-title">Shared Workspaces for Rent in Bermondsey</h1>
                    </div>
                    <h5 className="heading-title-2">Owned, constructed and operated by the same company for 25 years</h5>
                    <p>We have designed our co-working space near London Bridge, Bermondsey keeping your business needs in mind. Whether you are a small or a large team, we have offices for you.</p>
                  </div>
                </div>


                  <div className={HomeStyle.slider_box_cb}>
                    <div className={HomeStyle.grid_s_cb}>
                      {Object.values(resourceTypes).map(
                        (resourceType, i) =>
                          resourceType.active && (
                            <div className={HomeStyle.grid_m_cb} key={i}>
                              <div
                                className={`${HomeStyle.grid_inner_cb} set_loading_cb set_height_t_i_cb`}
                              >
                                <div className={"loader_cb"}>
                                  <div className={"loading_text_data"}>
                                    Loading Image...
                                  </div>
                                  <div className={"loading-spinner"}></div>
                                </div>

                                <div className={HomeStyle.grid_img_cb}>
                                  <Image
                                    src={resourceType?.bgImage}
                                    alt={resourceType?.name}
                                    className={HomeStyle.full_img}
                                    objectFit="cover"
                                    layout="fill"
                                    priority={false}
                                  />
                                </div>
                                <Link
                                  key={resourceType?._id}
                                  href={`/office-spaces/${resourceType?._id}`}
                                >
                                  <a>
                                    <div
                                      className={
                                        HomeStyle["desktop-content-container"]
                                      }
                                    >
                                      <Row
                                        align="middle"
                                        justify="space-between"
                                      >
                                        <h3 className={HomeStyle.title}>
                                          {resourceType?.name}
                                        </h3>

                                        <span
                                          className={HomeStyle["arrowIcon"]}
                                        >
                                          <i>
                                            <Image
                                              src={arrowIcon.src}
                                              alt="arrow-icon"
                                              width={23}
                                              height={10}
                                            />
                                          </i>
                                        </span>
                                      </Row>
                                    </div>

                                    <div
                                      className={
                                        HomeStyle["mobile-content-container"]
                                      }
                                    >
                                      <h3 className={HomeStyle.title}>
                                        {resourceType?.name}
                                      </h3>
                                      <span>
                                        View Details
                                        <i>
                                          <Image
                                            src={arrowIcon.src}
                                            alt="arrow-icon"
                                            width={23}
                                            height={10}
                                          />
                                        </i>
                                      </span>
                                    </div>
                                  </a>
                                </Link>
                              </div>
                            </div>
                          )
                      )}

                      <div className={HomeStyle.grid_m_cb}>
                        <div
                          className={`${HomeStyle.grid_inner_cb} set_loading_cb set_height_t_i_cb`}
                        >
                          <div className={"loader_cb"}>
                            <div className={"loading_text_data"}>
                              Loading Image...
                            </div>
                            <div className={HomeStyle["loading-spinner"]}></div>
                          </div>
                          <div className={HomeStyle.grid_img_cb}>
                            <Image
                              src={availability.src}
                              alt="Availability"
                              className={HomeStyle.full_img}
                              objectFit="cover"
                              layout="fill"
                              priority={false}
                            />
                          </div>
                          {/* <Link key={"other"} href="/availability">
                            <a>
                              <>
                                <h3 className={HomeStyle.title}>
                                  Availability
                                </h3>
                                <span>
                                  View Details
                                  <i>
                                    <img src={arrowIcon.src} alt="arrow-icon" />
                                  </i>
                                </span>
                              </>
                            </a>
                          </Link> */}

                          <Link key={"availability"} href={"/availability"}>
                            <a>
                              <div
                                className={
                                  HomeStyle["desktop-content-container"]
                                }
                              >
                                <Row align="middle" justify="space-between">
                                  <h3 className={HomeStyle.title}>
                                    Availability
                                  </h3>

                                  <span className={HomeStyle["arrowIcon"]}>
                                    <i>
                                      <Image
                                        src={arrowIcon.src}
                                        alt="arrow-icon"
                                        width={23}
                                        height={10}
                                      />
                                    </i>
                                  </span>
                                </Row>
                              </div>

                              <div
                                className={
                                  HomeStyle["mobile-content-container"]
                                }
                              >
                                <h3 className={HomeStyle.title}>
                                  Availability
                                </h3>
                                <span>
                                  View Details
                                  <i>
                                    <Image src={arrowIcon.src}
                                    alt="arrow-icon"
                                    width={23}
                                        height={10} />
                                  </i>
                                </span>
                              </div>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <Row align="middle" justify="center">
                      <div className={HomeStyle.map_link_cb}>
                        <span onClick={() => handleLocationPopup(true)}>
                          View on map
                          <i>
                            <Image
                            src={arrowIcon.src}
                            alt="arrow-icon"
                            width={23}
                            height={10}
                            />
                          </i>
                        </span>
                      </div>

                      {/* <div className={HomeStyle.learn_more_link_cb}>
                        <span onClick={() => handleLearnMorePopup(true)}>
                          Learn More
                          <i>
                            <Image
                            src={arrowIcon.src}
                            alt="arrow-icon"
                            width={23}
                            height={10}
                            />
                          </i>
                        </span>
                      </div> */}
                    </Row>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>


    </>
  );
};

export default React.memo(Sliderbanner);
